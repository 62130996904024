import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'
import axios from "axios"
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import './AddForm.css';

const fixtz = (tz) => {
  while (tz.toString().length < 9) {
    tz = "0" + tz;
  }
  if (tz.length > 9 && tz.charAt(tz.length - 1) === '0') {
    tz = tz.slice(0, -1)
  }
  return tz
}
const AddForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get('id') || 0;
  //const { from } = location.state
  const [year, setYear] = useState('');
  const [validated, setValidated] = useState(false);
  const [tzvalid, setTzvalid] = useState(true);
  const [form, setForm] = useState({
    formtype: id === '0' ? 0 : 1,
    fname: '',
    lname: '',
    tz: '',
    street: '',
    house: '',
    city: '',
    phone: '',
    cell: '',
    mail: '',
    prof: '',
    sname: sessionStorage.getItem('mosName'),
    scity: sessionStorage.getItem('city'),
    semel: sessionStorage.getItem('semel'),
    father: 0,
    sec: 0,
    clean: 0,
    sayat: 0,
    social: 0,
    doc: 0,
    class1: '',
    class2: '',
    class3: '',
    class4: '',
    class5: '',
    class6: '',
    class1s: 0,
    class2s: 0,
    class3s: 0,
    class4s: 0,
    class5s: 0,
    class6s: 0,
    teacher: 0,
    sub: 0,
    workpast: 0,
    workpastschool: "",
    workpastpay: "",
    familyworkers: "0",
    fwname1: "",
    fwname2: "",
    fwname3: "",
    fwtz1: "",
    fwtz2: "",
    fwtz3: "",
    fwtype1: "",
    fwtype2: "",
    fwtype3: "",
    fwjob1: "",
    fwjob2: "",
    fwjob3: "",
  });
  const [checkbox, setCheckbox] = useState({
    father: false,
    sec: false,
    clean: false,
    sayat: false,
    social: false,
    doc: false,
    class1: false,
    class2: false,
    class3: false,
    class4: false,
    class5: false,
    class6: false,
  });
  const date = new Date().toLocaleDateString("de-de")
  useEffect(() => {
    if (id > 0) {
      axios.post("api/form/user", { id })
        .then((res) => {
          console.log(res.data.form);
          if (res.data.msg === 'ok') {
            let temp = fixtz(res.data.form.tz)
            if (!tzValid(temp)) {
              setTzvalid(false)
            } else {
              setTzvalid(true)
            }
            res.data.form.tz = temp
            setForm(res.data.form)
            //console.log(res.data.form);
            setCheckbox({
              father: res.data.form.father !== 0,
              sec: res.data.form.sec !== 0,
              clean: res.data.form.clean !== 0,
              sayat: res.data.form.sayat !== 0,
              social: res.data.form.social !== 0,
              doc: res.data.form.doc !== 0,
              class1: res.data.form.class1 !== '',
              class2: res.data.form.class2 !== '',
              class3: res.data.form.class3 !== '',
              class4: res.data.form.class4 !== '',
              class5: res.data.form.class5 !== '',
              class6: res.data.form.class6 !== '',
            })
          }
        }).catch((error) => {
          console.error('Error:', error);
        });
    }

  }, []);
  useEffect(() => {
    console.log(form.tz);
    if (!tzValid() && form.tz !== '') {
      setTzvalid(false)
      console.log(false);
    } else {
      setTzvalid(true)
      console.log(true);
    }
  }, [form.tz]);

  const handleClick = (special, name) => {
    setForm({
      ...form,
      [name]: special === 0 ? 1 : 0
    })
  }

  const handleCheck = (name, special) => {
    setCheckbox({
      ...checkbox,
      [name]: !special
    })
    if (!name.includes('class')) {
      setForm({
        ...form,
        [name]: '0'
      })
    }

  }

  function tzValid(tz = form.tz) {
    tz = tz.toString()
    if (tz.length !== 9) {  // Make sure ID is formatted properly
      return false;
    }
    let sum = 0, incNum;
    for (let i = 0; i < tz.length; i++) {
      incNum = Number(tz[i]) * ((i % 2) + 1);  // Multiply number by 1 or 2
      sum += (incNum > 9) ? incNum - 9 : incNum;  // Sum the digits up and add to total
    }
    return (sum % 10 === 0);
  }
  const realSubmit = (go) => {
    axios.post("/api/form", { form, id, year })
      .then((res) => {
        if (go === 'back') {
          navigate('/principal')
        } else {
          window.print();
          navigate(`/confiles?id=${res.data.id}`)
        }


      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const handleChange = (e, type) => {
    let val = e.target.value;
    switch (type) {
      case 1:
        let clsses = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'יא', 'יב']
        if (e.target.name.includes('class')) {
          if (!clsses.includes(val)) {
            val = '';
          }
        }
        setForm({
          ...form,
          [e.target.name]: val.replace(/\d/g, '')
        });
        break;
      case 2:
        let job = ["father", "sec", "clean", "sayat", "social", "doc"]
        if (job.includes(e.target.name)) {
          let total = parseInt(form.father) + parseInt(form.sec) + parseInt(form.clean) + parseInt(form.sayat) + parseInt(form.social) + parseInt(form.doc) - parseInt(form[e.target.name])
          if (100 < total + parseInt(val)) {
            val = (100 - total).toString()
          }
        }
        if (e.target.name === 'phone' || e.target.name === 'cell') {
          val = val[0] == '0' ? val : `0${val}`;
        }
        setForm({
          ...form,
          [e.target.name]: val.replace(/[^\d]/g, "")
        })
        break;
      case 3:
        setForm({
          ...form,
          [e.target.name]: val
        })
        break;
      case 4:
        if (e.target.checked) {
          setForm({
            ...form,
            [e.target.name]: 1
          })
        } else {
          setForm({
            ...form,
            [e.target.name]: 0
          })
        }
        break;
      case 5:
        setForm({
          ...form,
          [e.target.name]: val.replace(/[^0-9\/]/g, "")
        })
        break;

      default:
        break;
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const thisform = event.currentTarget;

    if (!tzValid()) {
      setTzvalid(false)
      thisform.elements[2].scrollIntoView({ behavior: 'smooth' });
    }
    else if (thisform.checkValidity() === true) {
      console.log('send');
      realSubmit("continue")
    } else {
      // Form is invalid
      const formElements = thisform.elements;
      let firstInvalidField = null;

      for (let i = 0; i < formElements.length; i++) {
        const field = formElements[i];
        if (!field.checkValidity()) {
          firstInvalidField = field;
          break;
        }
      }
      if (firstInvalidField) {
        const label = firstInvalidField.labels[0];
        if (label) {
          label.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          firstInvalidField.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
    setValidated(true);
  }

  let index = 1;
  return (
    <Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Card className="mt-2 mb-2">
          <Card.Body>
            <h1 className='text-center'>
              {parseInt(form.formtype) === 0 ? 'עובד הוראה' : 'עובד עזר'} - {year}
            </h1>
            <p>{index++}. <u>פרטים אישיים</u></p>
            <Row>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="lname">
                  <Form.Label>שם משפחה*</Form.Label>
                  <Form.Control type="text" placeholder="" name="lname" value={form.lname} required pattern='[\u0590-\u05FF\s"]+' onChange={(e) => handleChange(e, 1)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא שם משפחה בעברית
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="fname">
                  <Form.Label>שם פרטי*</Form.Label>
                  <Form.Control type="text" placeholder="" name="fname" value={form.fname} required pattern='[\u0590-\u05FF\s"]+' onChange={(e) => handleChange(e, 1)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא שם פרטי
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="tz">
                  <Form.Label>מספר ת.ז.*</Form.Label>
                  <Form.Control type="text" className={tzvalid ? "" : "is-invalid"} placeholder="" pattern={tzvalid ? "\\d{9}" : "\\d{10}"} maxLength={9} name="tz" value={form.tz} required onChange={(e) => handleChange(e, 2)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא תז תקין
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="street">
                  <Form.Label>רחוב*</Form.Label>
                  <Form.Control type="text" placeholder="" name="street" value={form.street} required pattern={`[\\u0590-\\u05FF\\s"',.]+`} onChange={(e) => handleChange(e, 1)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא שם רחוב
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="house">
                  <Form.Label>מספר בית*</Form.Label>
                  <Form.Control type="text" placeholder="" name="house" value={form.house} required onChange={(e) => handleChange(e, 5)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא מס' בית
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>עיר*</Form.Label>
                  <Form.Control type="text" placeholder="" name="city" value={form.city} required pattern={`[\\u0590-\\u05FF\\s"',.]+`} onChange={(e) => handleChange(e, 1)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא שם עיר
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>טלפון</Form.Label>
                  <Form.Control type="tel" placeholder="" maxLength={10} name="phone" value={form.phone} onChange={(e) => handleChange(e, 2)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא טלפון
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="cell">
                  <Form.Label>טלפון נייד*</Form.Label>
                  <Form.Control type="tel" placeholder="" maxLength={10} pattern='\d{10}' name="cell" value={form.cell} required onChange={(e) => handleChange(e, 2)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא טלפון נייד
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="mail">
                  <Form.Label>כתובת מייל*</Form.Label>
                  <Form.Control type="email" placeholder="" required pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*" name="mail" value={form.mail} onChange={(e) => handleChange(e, 3)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא מייל תקין
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={8} xl={8} xxl={8} className={form.formtype === 1 ? "d-none d-print-none" : ""}>
                <Form.Group className="mb-3" controlId="prof">
                  <Form.Label>תחום התמחות כפי שמופיע באישור נתוני העסקה</Form.Label>
                  <Form.Control type="text" placeholder="" name="prof" value={form.prof} onChange={(e) => handleChange(e, 1)} />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא פרטים
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <p>{index++}. <u>פרטי בית הספר</u></p>
            <Row>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="sname">
                  <Form.Label>שם בית הספר</Form.Label>
                  <Form.Control type="text" placeholder="" name="sname" value={form.sname} required readOnly />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא שם בית הספר
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="scity">
                  <Form.Label>עיר</Form.Label>
                  <Form.Control type="text" placeholder="" name="scity" value={form.scity} required readOnly />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא שם העיר
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
                <Form.Group className="mb-3" controlId="semel">
                  <Form.Label>סמל מוסד</Form.Label>
                  <Form.Control type="text" placeholder="" name="semel" value={form.semel} required readOnly />
                  <Form.Control.Feedback className="d-print-none">נראה טוב!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" className="d-print-none">
                    נא למלא סמל מוסד
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {form.formtype === 1 ?
              <div>
                <p>{index++}. <u>התפקיד אותו מיועד/ת למלא</u><span className="d-print-none">&nbsp;(סך כל אחוזי המשרה בכל התפקידים לא יכול להיות מעל 100 אחוז)</span></p>
                <Table bordered className="pagebreak">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>התפקיד אותו מיועד/ת למלא</th>
                      <th>אחוזי המשרה המבוקשים</th>
                      <th>שעות עבודה שבועיות</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><Form.Check type="checkbox" name="father" onChange={(e) => handleCheck(e.target.name, checkbox.father)} checked={form.father > 0 || checkbox.father} /></td>
                      <td>אב בית / שרת</td>
                      <td><Form.Control type="text" placeholder="" name="father" value={form.father} disabled={!checkbox.father} required={checkbox.father} pattern='^(?!0$).*' onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" value={Math.round(form.father / 10 * 40) / 10} disabled /></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="sec" onChange={(e) => handleCheck(e.target.name, checkbox.sec)} checked={form.sec > 0 || checkbox.sec} /></td>
                      <td>מזכיר/ה</td>
                      <td><Form.Control type="text" placeholder="" name="sec" value={form.sec} disabled={!checkbox.sec} required={checkbox.sec} pattern='^(?!0$).*' onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" value={Math.round(form.sec / 10 * 40) / 10} disabled /></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="clean" onChange={(e) => handleCheck(e.target.name, checkbox.clean)} checked={form.clean > 0 || checkbox.clean} /></td>
                      <td>עובד/ת ניקיון</td>
                      <td><Form.Control type="text" placeholder="" name="clean" value={form.clean} disabled={!checkbox.clean} required={checkbox.clean} pattern='^(?!0$).*' onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" value={Math.round(form.clean / 10 * 40) / 10} disabled /></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="sayat" onChange={(e) => handleCheck(e.target.name, checkbox.sayat)} checked={form.sayat > 0 || checkbox.sayat} /></td>
                      <td>סייע/ת</td>
                      <td><Form.Control type="text" placeholder="" name="sayat" value={form.sayat} disabled={!checkbox.sayat} required={checkbox.sayat} pattern='^(?!0$).*' onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" value={Math.round(form.sayat / 10 * 40) / 10} disabled /></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="social" onChange={(e) => handleCheck(e.target.name, checkbox.social)} checked={form.social > 0 || checkbox.social} /></td>
                      <td>עו"ס</td>
                      <td><Form.Control type="text" placeholder="" name="social" value={form.social} disabled={!checkbox.social} required={checkbox.social} pattern='^(?!0$).*' onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" value={Math.round(form.social / 10 * 40) / 10} disabled /></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="doc" onChange={(e) => handleCheck(e.target.name, checkbox.doc)} checked={form.doc > 0 || checkbox.doc} /></td>
                      <td>רופא</td>
                      <td><Form.Control type="text" placeholder="" name="doc" value={form.doc} disabled={!checkbox.doc} required={checkbox.doc} pattern='^(?!0$).*' onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" value={Math.round(form.doc / 10 * 40) / 10} disabled /></td>
                    </tr>
                  </tbody>
                </Table>
              </div> :
              <div>
                <p>{index++}. <u>התפקיד אותו מיועד/ת למלא</u></p>
                <Table bordered className={form.formtype === 1 ? "d-none d-print-none" : ""}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>התפקיד אותו מיועד/ת למלא</th>
                      <th>כיתה</th>
                      <th>חינוך מיוחד (כן /לא)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><Form.Check type="checkbox" name="class1" onChange={(e) => handleCheck(e.target.name, checkbox.class1)} checked={form.class1 === 'undefined' || checkbox.class1} /></td>
                      <td>מחנך/כת כיתה</td>
                      <td><Form.Control type="text" placeholder="" name="class1" value={form.class1} disabled={!checkbox.class1} required={checkbox.class1} maxLength='2' onChange={(e) => handleChange(e, 1)} /></td>
                      <td><p onClick={() => handleClick(form.class1s, "class1s")}>{form.class1s === 0 ? "לא" : "כן"}</p></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="class2" onChange={(e) => handleCheck(e.target.name, checkbox.class2)} checked={form.class2 === 'undefined' || checkbox.class2} /></td>
                      <td>מורה מקצועי/ת</td>
                      <td><Form.Control type="text" placeholder="" name="class2" value={form.class2} disabled={!checkbox.class2} required={checkbox.class2} maxLength='2' onChange={(e) => handleChange(e, 1)} /></td>
                      <td><p onClick={() => handleClick(form.class2s, "class2s")}>{form.class2s === 0 ? "לא" : "כן"}</p></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="class6" onChange={(e) => handleCheck(e.target.name, checkbox.class6)} checked={form.class6 === 'undefined' || checkbox.class6} /></td>
                      <td>שילוב/ מקדמת/ סל אישי</td>
                      <td><Form.Control type="text" placeholder="" name="class6" value={form.class6} disabled={!checkbox.class6} required={checkbox.class6} maxLength='2' onChange={(e) => handleChange(e, 1)} /></td>
                      <td><p onClick={() => handleClick(form.class6s, "class6s")}>{form.class6s === 0 ? "לא" : "כן"}</p></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="class3" onChange={(e) => handleCheck(e.target.name, checkbox.class3)} checked={form.class3 === 'undefined' || checkbox.class3} /></td>
                      <td>פרא רפואי <b>להקיף הנדרש-</b> טיפול באומנויות/ ריפוי בעיסוק/ קלינאית תקשורת</td>
                      <td><Form.Control type="text" placeholder="" name="class3" value={form.class3} disabled={!checkbox.class3} required={checkbox.class3} maxLength='2' onChange={(e) => handleChange(e, 1)} /></td>
                      <td><p onClick={() => handleClick(form.class3s, "class3s")}>{form.class3s === 0 ? "לא" : "כן"}</p></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="class4" onChange={(e) => handleCheck(e.target.name, checkbox.class4)} checked={form.class4 === 'undefined' || checkbox.class4} /></td>
                      <td>ייעוץ/ניהול/הדרכה/הנחיה <b>-להקיף הנדרש</b></td>
                      <td><Form.Control type="text" placeholder="" name="class4" value={form.class4} disabled={!checkbox.class4} required={checkbox.class4} maxLength='2' onChange={(e) => handleChange(e, 1)} /></td>
                      <td><p onClick={() => handleClick(form.class4s, "class4s")}>{form.class4s === 0 ? "לא" : "כן"}</p></td>
                    </tr>
                    <tr>
                      <td><Form.Check type="checkbox" name="class5" onChange={(e) => handleCheck(e.target.name, checkbox.class5)} checked={form.class5 === 'undefined' || checkbox.class5} /></td>
                      <td>אחר</td>
                      <td><Form.Control type="text" placeholder="" name="class5" value={form.class5} disabled={!checkbox.class5} required={checkbox.class5} maxLength='2' onChange={(e) => handleChange(e, 1)} /></td>
                      <td><p onClick={() => handleClick(form.class5s, "class5s")}>{form.class5s === 0 ? "לא" : "כן"}</p></td>
                    </tr>
                  </tbody>
                </Table>
              </div>}
            {form.formtype === 0 &&
              <div className="pagebreak">
                <p>{form.formtype === 1 ? '' : index++}. <u>סטטוס ההעסקה </u></p>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>סטטוס ההעסקה</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><Form.Check name="teacher" type="checkbox" checked={form.teacher === 1} onChange={(e) => handleChange(e, 4)} /></td>
                      <td>משרת עובד/ת הוראה</td>
                    </tr>
                    <tr>
                      <td><Form.Check name="sub" type="checkbox" checked={form.sub === 1} onChange={(e) => handleChange(e, 4)} /></td>
                      <td>מילוי מקום לתקופה קצובה העולה על 51 יום</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            }
            <div>
              <p>{index++}. <u>הצהרת המועמד לנושא קרבת משפחה</u></p>
              <u>הגדרות</u><br />
              <b>1. </b>רשת החינוך "בני יוסף" (להלן: "הרשת").<br />
              <b>2. </b>מוסד חינוכי הפועל במסגרת ה"רשת" (להלן: "המוסד").<br />
              <b>3. </b>עובד המועסק במוסד בכל משרה שהיא לרבות עובדי עזר (להלן: "עובד המוסד").<br />
              <b>4. </b>על פי חוזה מנכ"ל משרד החינוך קרוב משפחה יחשב אחד מאלה: בן או בת הזוג לרבות ידוע בציבור, הורה, הורי הורה, בן או בת ובני זוגם, אח או אחות וילדיהם, גיס גיסה, דוד, דודה, חותן, חותנת, חם חמות, נכד או נכדה, לרבות קרוב כאמור שהוא שלוב או מאומץ לרבות בני זוגם.<br />
              <br />
              <u>התחייבות העובד</u><br />
              אני הח"מ שפרטיו מופיעים לעיל בסעיף 1, מועמד להעסקה ב"רשת" במוסד המפורט לעיל בסעיף 2, מצהיר כמפורט:<br />
              <Form.Check
                className='mt-3 ms-3'
                inline
                name="familyworkers"
                type='radio'
                checked={form.familyworkers == '0'}
                value='0'
                onChange={(e) => handleChange(e, 3)}
              />אין לי קרוב/י משפחה מכל המנויים לעיל המועסק/ים  כעובד/ים במוסד בו אני מועמד לעבודה.<br />
              <Form.Check
                className='mt-3 ms-3'
                inline
                name="familyworkers"
                type='radio'
                checked={form.familyworkers == '1'}
                value='1'
                onChange={(e) => handleChange(e, 3)}
              />מצהיר כי יש  לי קרוב/י משפחה מכל המנויים לעיל המועסק/ים  כעובד/ים במוסד בו אני מועמד לעבודה.<br />
              <div className={form.familyworkers == '0' ? 'd-none' : ''}>
                <Row className='mt-2'>
                  פרט/י כדלקמן:
                </Row>
                <Table bordered>
                  <thead>
                    <tr>
                      <th className='col-3'>שם קרוב/ת המשפחה</th>
                      <th className='col-3'>ת.ז.</th>
                      <th className='col-3'>מהות הקרבה המשפחתית</th>
                      <th className='col-3'>תפקידו/ה של קרוב/ת המשפחה</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><Form.Control required={form.familyworkers == '1'} type="text" placeholder="" name="fwname1" value={form.fwname1} onChange={(e) => handleChange(e, 1)} /></td>
                      <td><Form.Control required={form.familyworkers == '1'} type="text" placeholder="" name="fwtz1" value={form.fwtz1} pattern={form.familyworkers == '1' ? (tzValid(form.fwtz1) ? '\\d{9}' : '\\d{10}') : ".*"} maxLength={9} onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control required={form.familyworkers == '1'} type="text" placeholder="" name="fwtype1" value={form.fwtype1} onChange={(e) => handleChange(e, 1)} /></td>
                      <td><Form.Control required={form.familyworkers == '1'} type="text" placeholder="" name="fwjob1" value={form.fwjob1} onChange={(e) => handleChange(e, 1)} /></td>
                    </tr>
                    <tr>
                      <td><Form.Control type="text" placeholder="" name="fwname2" value={form.fwname2} onChange={(e) => handleChange(e, 1)} /></td>
                      <td><Form.Control type="text" placeholder="" name="fwtz2" value={form.fwtz2} onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" name="fwtype2" value={form.fwtype2} onChange={(e) => handleChange(e, 1)} /></td>
                      <td><Form.Control type="text" placeholder="" name="fwjob2" value={form.fwjob2} onChange={(e) => handleChange(e, 1)} /></td>
                    </tr>
                    <tr>
                      <td><Form.Control type="text" placeholder="" name="fwname3" value={form.fwname3} onChange={(e) => handleChange(e, 1)} /></td>
                      <td><Form.Control type="text" placeholder="" name="fwtz3" value={form.fwtz3} onChange={(e) => handleChange(e, 2)} /></td>
                      <td><Form.Control type="text" placeholder="" name="fwtype3" value={form.fwtype3} onChange={(e) => handleChange(e, 1)} /></td>
                      <td><Form.Control type="text" placeholder="" name="fwjob3" value={form.fwjob3} onChange={(e) => handleChange(e, 1)} /></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className='d-print-block d-none pagebreak'>
              <u>הצהרת העובד/ת:</u><br />
              אני, העובד/ת שפרטיו/ה מצוינים לעיל בסעיף 1, מצהיר/ה ומאשר בזה שהפרטים שמסרתי בטופס זה הם מלאים ונכונים.
              ידוע לי כי אני מחויב/ת להודיע למרכז על שינויים בנתוני
              האישיים, ובכלל זה עלי להודיע על שינוי שבעקבותיו נוצרה קרבת משפחה ביני לבין עובד/ת אחר/ת במוסד בתוך 30 ימים.<br /><br />
              <Row>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" readOnly value={date} />
                    <Form.Label>תאריך</Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4} />
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" disabled />
                    <Form.Label>חתימת העובד</Form.Label>
                  </Form.Group>
                </Col>
              </Row>

            </div>
            <div>
              <p>{index++}. <u>הצהרה, התחייבות ושיפוי</u></p>
              אני הח"מ שפרטיו מופיעים לעיל בסעיף 1, מועמד להעסקה ב"רשת" במוסד המפורט לעיל בסעיף 2, מצהיר כמפורט:<br />
              <Form.Check
                className='mt-3 ms-3'
                inline
                name="workpast"
                checked={form.workpast == '0'}
                onChange={(e) => handleChange(e, 3)}
                type='radio'
                value='0'
              />הריני מצהיר כי <u>לא הועסקתי</u> בשנה"ל הקודמת במוסדות הנמנים על "רשת החינוך בני יוסף"<br />
              <Form.Check
                className='mt-3 ms-3'
                inline
                name="workpast"
                checked={form.workpast == '1'}
                onChange={(e) => handleChange(e, 3)}
                type='radio'
                value='1'
              />הריני <u>מצהיר</u> כי הועסקתי בשנה"ל הקודמת במוסדות הנמנים על "רשת החינוך בני יוסף" כדלהלן<br />

            </div>
            {form.formtype === 1 ?
              <>
                <div className='d-print-block d-none'>
                  והואיל: והועסקתי במוסד הנמנה בשנת הלימודים {year === 'תשפ"ה' ? 'תשפ"ד' : 'תשפ"ג'} ו/או המצטרף בשנת הלימודים {year === 'תשפ"ה' ? 'תשפ"ה' : 'תשפ"ד'} לרשת החינוך "בני יוסף" (להלן: "רשת החינוך") ו/או שהוחלט להעסיק אותי ולשלם את שכרי באמצעות "רשת החינוך". <br />
                  לפיכך אני מצהיר/ה ומאשר/ת כדלקמן :<br />
                  <div className='d-flex'>
                    <p>1.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>	כל התחשבנות שהיא ו/או זכויות ככול שישנם אם ישנם בני לבין המעסיק הקודם עד למועד כניסתי לעבודה באמצעות "רשת החינוך" כולל זכאות לפיצויי פיטורין הינם על אחריותי.
                      והריני להבהיר כי, התחשבנות זו היא ביני לבין המעסיק הקודם ששלם את שכרי עד למועד כניסתי לעבודה באמצעות "רשת החינוך" ולא אהיה זכאי/ת לכל תשלום מ"רשת החינוך" בקשר לתקופת עבודתי וסיום תקופת עבודתי אצלו.
                    </div>
                  </div>
                  <div className='d-flex'>
                    <p>2.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div> ידוע לי כי עם סיום תקופת עבודתי ב"רשת החינוך", במידה ואהיה זכאי/ת לפיצויי פיטורין מ"רשת החינוך", תחושב זכאותי לפיצויים החל מיום תחילת עבודתי ותשלום שכרי באמצעות הרשת בלבד.</div>
                  </div>
                  <div className='d-flex'>
                    <p>3.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>אני מתחייב/ת לא לתבוע את "רשת החינוך" בגין תקופת עבודתי ותשלום שכרי מהמעסיק הקודם וסיום תקופת עבודתי אצלו, לרבות לא בקשר לתשלום פיצויי פיטורין.</div>
                  </div>
                  <div className='d-flex'>
                    <p>4.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>אני מאשר/ת כי במידה ואפר את התחייבויותיי האמורות לעיל, אפצה את "רשת החינוך" בגין כל הנזקים הישירים והעקיפים שיגרמו לה כתוצאה מכך.</div>
                  </div>
                  <div className='d-flex'>
                    <p>5.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>	אני מאשר/ת כי ידוע לי כי על יסוד התחייבויותיי והצהרותיי לעיל, "רשת החינוך" מסכימה להעסיק אותי ואני מאשר/ת כי במידה ואפר את התחייבויותיי כאמור לעיל, הרשת תהיה רשאית לקזז מסכומים המגיעים לי ממנה את הנזקים שיגרמו לה כתוצאה מהפרת התחייבויותיי.</div>
                  </div>
                  <div className='d-flex'>
                    <p>6.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>
                      ידוע לי שלושת החודשים הראשונים להעסקתי יהיו לניסיון בלבד ו"רשת החינוך" תהיה רשאית להפסיק את ההסכם בתקופה זו, ללא כל פיצוי מעבר לתשלום שכרי בתקופת העסקתי בפועל.
                    </div>
                  </div><br />
                  <u>הצהרת העובד/ת:</u><br />
                  אני, העובד/ת שפרטיו/ה מצוינים לעיל בסעיף 1, מצהיר/ה ומאשר בזה שקראתי פרק 'הצהרה, התחייבות ושיפוי' ו/או הוסבר לי והבנתי את כל הפרטים המובאים בו ושכל הפרטים שמסרתי בטופס זה הם מלאים ונכונים. <br />
                  <Row className='pagebreak'>
                    <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" readOnly value={date} />
                        <Form.Label>תאריך</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xs={4} md={4} lg={4} xl={4} xxl={4} />
                    <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" disabled />
                        <Form.Label>חתימת העובד</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </> :
              <>
                <div className='d-print-block d-none'>
                  והואיל: והועסקתי במוסד הנמנה בשנת הלימודים {year === 'תשפ"ה' ? 'תשפ"ד' : 'תשפ"ג'} ו/או המצטרף בשנת הלימודים {year} לרשת החינוך "בני יוסף" (להלן: "רשת החינוך") ו/או שהוחלט להעסיק אותי ולשלם את שכרי באמצעות "רשת החינוך". <br />
                  לפיכך אני מצהיר/ה ומאשר/ת כדלקמן :<br />
                  <div className='d-flex'>
                    <p>1.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>כל התחשבנות שהיא ו/או זכויות ככול שישנם אם ישנם בני לבין הגוף ששילם את שכרי (להלן: המעסיק ו/או מעסיקים קודמים) עד למועד כניסתי לעבודה באמצעות רשת החינוך התורני "בני יוסף" (להלן – הרשת) כולל זכאות לפיצויי פיטורין הינם על אחריותי.
                      והריני להבהיר כי, התחשבנות זו היא ביני לבין המעסיק הקודם ששלם את שכרי עד למועד כניסתי לעבודה באמצעות הרשת ולא אהיה זכאי/ת לכל תשלום מהרשת בקשר לתקופת עבודתי וסיום תקופת עבודתי אצל המעסיק הקודם.
                    </div>
                  </div>
                  <div className='d-flex'>
                    <p>2.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div> ידוע לי כי עם סיום תקופת עבודתי ב"רשת החינוך", במידה ואהיה זכאי/ת לפיצויי פיטורין מ"רשת החינוך", תחושב זכאותי לפיצויים החל מיום תחילת עבודתי ותשלום שכרי באמצעות הרשת בלבד.</div>
                  </div>
                  <div className='d-flex'>
                    <p>3.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>אני מתחייב/ת לא לתבוע את הרשת בגין תקופת עבודתי ו/או אי תשלום שכרי ו/או בגין תנאי עבודה ו/או פיצויי פיטורין ו/או כל דבר הנובע מהעסקה ו/או מסיום יחסי עובד ומעביד הקשורים והנובעים מתקופת העסקתי אצל  מעסיקים קודמים.</div>
                  </div>
                  <div className='d-flex'>
                    <p>4.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>אני מאשר/ת כי במידה ואפר את התחייבויותיי האמורות לעיל, אפצה את הרשת בגין כל הנזקים הישירים והעקיפים שיגרמו לה כתוצאה מכך.</div>
                  </div>
                  <div className='d-flex'>
                    <p>5.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>אני מאשר/ת כי ידוע לי כי על יסוד התחייבויותיי והצהרותיי לעיל, הרשת מסכימה להעסיק אותי ואני מאשר/ת כי במידה ואפר את התחייבויותיי כאמור לעיל, הרשת תהיה רשאית לקזז מסכומים המגיעים לי ממנה את הנזקים שיגרמו לה כתוצאה מהפרת התחייבויותיי.</div>
                  </div>
                  <div className='d-flex pagebreak'>
                    <p>6.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>
                      כאמור ב"הודעה לעובד" ידוע לי שתקופת העסקתי תהיה לשנת הלימודים {year} בלבד. ושהארכת ההסכם, אם תעשה, תהיה <u>בכתב בלבד</u> וכל עוד מנהל אגף כח אדם של הרשת לא אישר את הארכת ההסכם, לא תהיה לי כל טענה שהרשת מחויבת להעסיק אותי מעבר לשנת הלימודים {year}. כמו כן, אני מודע לכך שלמרות האמור לעיל, שלושת החודשים הראשונים להעסקתי יהיו לניסיון בלבד והרשת תהיה רשאית להפסיק את ההסכם בתקופה זו, ללא כל פיצוי מעבר לתשלום שכרי בתקופת העסקתי בפועל.
                    </div>
                  </div><br />
                  <u>הצהרת העובד/ת:</u><br />
                  אני, העובד/ת שפרטיו/ה מצוינים לעיל בסעיף 1, מצהיר/ה בזה שהפרטים המצוינים לעיל ב"הצהרה התחייבות ושיפוי" מקובלים עלי וקבלתם מהווה תנאי להעסקתי ברשת החינוך התורני  "בני יוסף".
                  ובחתימתי על מסמך זה אני מאשר שקראתי והבנתי את ה"הודעה לעובד" על כל סעיפיו ואני מסכים לכל האמור בו.
                  <br />
                  <Row className='pagebreak'>
                    <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" readOnly value={date} />
                        <Form.Label>תאריך</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xs={4} md={4} lg={4} xl={4} xxl={4} />
                    <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" disabled />
                        <Form.Label>חתימת העובד</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </>
            }
            <div className="mb-3 d-print-none">
              <br />
              <hr />
              המשך התהליך:<br />
              א. יש להדפיס את הטופס<br />
              ב. מנהל ביה"ס, המועמד ומפקח הרשת צריכים לחתום במקומות המתאימים<br />
              ג. לסרוק ולהעלות את הטופס החתום במערכת בהמשך התהליך<br />
              <Button className="m-2" variant="primary" name="back" onClick={(e) => realSubmit(e.target.name)}>שמור כטיוטה</Button>
              {parseInt(form.formtype) === 0 && <Button className="m-2" variant="primary" type="submit" onClick={() => setYear('תשפ"ד')}>הדפסת הטופס תשפ"ד </Button>}
              <Button className="m-2" variant="primary" type="submit" onClick={() => setYear('תשפ"ה')}>הדפסת הטופס תשפ"ה </Button>
            </div>
            <div className="d-print-block d-none">
              <p>{index++}. <u>הודעה לעובד - תנאי העסקה</u></p>
              {form.formtype === 0 ? <>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;</p>
                  <div>הודעה זו מהווה ראיה לכך שהובאו לידיעת העובד כל תנאי העסקתו ברשת החינוך התורני "בני יוסף" ע.ר 580121952 (להלן :"הרשת") וההוראות והסעיפים המופיעים בערכת פתיחת תיק עובד  הוראה.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;</p>
                  <div>המועמד (להלן: "העובד") שפרטיו מפורטים בסעיף 1 יועסק בבית הספר שפרטיו מפורטים בסעיף 2, בתפקיד המפורט בסעיף 3.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;</p>
                  <div>אישור העסקה הוא לשנה"ל {year}, באופן כזה שהעסקתך תסתיים ביום- 31.8.{year === 'תשפ"ה' ? '2025' : '2024'}.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;</p>
                  <div>שלושת החודשים הראשונים להעסקתי יהיו לניסיון בלבד והרשת תהיה רשאית להפסיק את ההסכם בתקופה זו, ללא כל פיצוי מעבר לתשלום השכר על תקופת העסקה בפועל.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;</p>
                  <div>כל העסקה נוספת כפופה לאישור שיבוץ בכתב שישלח ע"י מ. אגף כ.א. בהוראה.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;6.&nbsp;</p>
                  <div>לידיעה: 3 שנים ראשונות לעו"ה מוסמך הם בגדר ניסיון ולעו"ה שאינו מוסמך עד 5 שנים. בכפוף לחוות דעת פיקוח, הרשת רשאית להאריך את הניסיון בתקופות נוספת.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;7.&nbsp;</p>
                  <div>העסקת העובד ברשת תהיה בכפוף להקצאת שעות הוראה למוסד ולמורה. </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;8.&nbsp;</p>
                  <div>אין לראות באישור העסקה ברשת "בני  יוסף" כאישור על רצף זכויות ממקום עבודה קודם.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;9.&nbsp;</p>
                  <div>בחתימת העובד על מסמך זה, העובד מצהיר כי הוא סיים את ההתחשבנויות הכספיות והאחרות הקשורות לתקופת העסקתו אצל מעסיקים קודמים ואין לו ולא תהינה לו כל תביעות ו/או דרישות הנובעות משכר עבודה וסיום התקשרות ו/או דרישות אחרות בגין תקופות עבודתו אצל מעסיקים קודמים.  </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;10.&nbsp;</p>
                  <div>על העובד חלה חובה לפתוח תיק עו"ה במערכת פורטל לעובדי הוראה במשרד החינוך. פתיחת תיק עו"ה במשרד החינוך ומסירת מסמכי קליטת עובד חדש לרשת לא מהווה ראיה ואו אישור על קבלתו לעבודה.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;11.&nbsp;</p>
                  <div>העסקת העובד ברשת מותנית בקבלת אישור פדגוגי, אישור ביטחוני, אישור בדבר קביעת פרופיל לשכר מטעם משרד החינוך.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;12.&nbsp;</p>
                  <div>העסקת עובד ברשת החינוך "בני יוסף" וקליטתו במערכת, מותנים באישור סופי של הנהלת רשת החינוך "בני יוסף" שתדון ותחליט בבקשה לאחר שהעובד יגיש את המסמכים לפתיחת תיק עו"ה במשרד החינוך ומסירת מסמכי קליטת עובד חדש לרשת במלואם. האישור ינתן בכתב חתום בידי מנהל כוח אדם ברשת החינוך בלבד. בהקשר זה, אין לראות בביצוע הליך ו/או אישור כלשהו של מנהל בית הספר ו/או המפקח/ת של רשת החינוך "בני יוסף" ו/או בכל גורם אחר כאישור קבלה לעבודה ברשת. כמו כן, אין לראות בהנפקת תלוש שכר של רשת החינוך "בני יוסף" כאישור העסקה.
                  </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;13.&nbsp;</p>
                  <div>ידוע לעובד שבמידה וקיימת קרבת משפחה: העסקתו ברשת החינוך "בני יוסף", מותנים באישור סופי של הוועדה לקרבת משפחה  ברשת החינוך "בני יוסף" שתדון ותחליט בבקשה לאחר הגשת המסמכים לפתיחת תיק עו"ה.
                    בהקשר זה, אין לראות בביצוע הליך ו/או אישור כל שהוא של מנהל ביה"ס ו/או המפקח/ת של רשת החינוך "בני     יוסף" ו/או בכול גורם אחר כאישור קבלה לעבודה ברשת.
                    כמו כן, אין לראות בהנפקת תלוש שכר של רשת החינוך "בני  יוסף" כאישור העסקה.
                  </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;14.&nbsp;</p>
                  <div>העובד יועסק במוסד בהיקף משרה, במערכת שיעורים ובתפקיד שיועד לו ע"י מנהל בית הספר והפיקוח ומנהל אגף כח אדם ברשת יחדיו בכל שנת לימודים בהתאמה לצרכי המערכת ובית הספר.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;15.&nbsp;</p>
                  <div>כאשר צרכי בית הספר ו/או התקציב מחייבים זאת, רשאית הרשת לשנות גם במהלך שנת הלימודים את סידור העבודה כולל ביצוע שינויים בתפקידו של העובד ולהודיע על כך לעובד. </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;16.&nbsp;</p>
                  <div>עובד שמועסק בהיקף משרה בת 100%, תעמוד המשרה בהתאם לסטטוס העסקת העובד ב"עולם הישן" ו/או ב"עולם החדש" בהתאמה.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;17.&nbsp;</p>
                  <div>העובד יציית להוראות הממונים עליו ויעבוד בתפקידו במסירות ונאמנות.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;18.&nbsp;</p>
                  <div>העובד מתחייב להודיע מידית אם יש מניעה רפואית ו/או אחרת לביצוע תפקידו.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;19.&nbsp;</p>
                  <div>מובהר ומודגש ! כי העובד מודע ומסכים לכך שימי העבודה וימי החופשה של העובד בתקופות העסקתו ברשת הינה בהתאם ללוח ימי העבודה והחופשות שהרשת מפרסמת מדי שנת לימודים.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;20.&nbsp;</p>
                  <div>העובד יהיה נוכח בימי הערכות, עיון, השתלמויות, מסיבות וכנסים שהרשת ו/או המוסד עורכים מידי שנה במהלך שנת הלימודים גם אם אלו נערכים בימי חופשה.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;21.&nbsp;</p>
                  <div>העובד לא יעבוד שעות נוספות אלא באישור בכתב  ממנהל כח אדם של הרשת בלבד.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;22.&nbsp;</p>
                  <div>המשכורת המשולמת לעובד הינה בהתאם ל "אישור נתוני שכר" של העובד כפי שיקבע במשרד החינוך.  </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;23.&nbsp;</p>
                  <div>השכר של העובד ב"עולם הישן" יכלול את המרכיבים הבאים: שכר משולב, הסכם 87, תגבור 94, תוספת 2001, תוספת 2008, תוספת 2011, תוספת 2016. ותוספת גמול תפקיד ע"פ זכאות אישית. . וב"עולם החדש" בשינויים המחוייבים בהתאמה לתוכנית "אופק חדש".</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;24.&nbsp;</p>
                  <div>העובד יהיה זכאי לתשלום נסיעות וטלפון בהתאם לנהוג לגבי עובדי הוראה המועסקים בחינוך הרשמי.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;25.&nbsp;</p>
                  <div>העובד יהיה זכאי לדמי מחלה בהתאם להוראות חוק דמי מחלה תשל"ו- 1976 בלבד. </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;26.&nbsp;</p>
                  <div>מוצהר בזאת כי ימי מחלה צבורים ניתנים לפדיון בעת פרישתו של העובד לגמלאות ובכפוף לתנאים הנהוגים ושיהיו נהוגים בעת פרישת העובד לגמלאות לגבי עובדי הוראה המועסקים בחינוך הרשמי.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;27.&nbsp;</p>
                  <div>העובד יהיה זכאי לדמי ביגוד והבראה בהתאם לזכאות של עובדי הוראה המועסקים בחינוך הרשמי. </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;28.&nbsp;</p>
                  <div>העובד יהיה זכאי להצטרף לקרן השתלמות לעובדי הוראה בהתאם לנוהל הצטרפות לקרן השתלמות ברשת. על העובד חלה המחויבות לוודא בסמוך לכניסתו לעבודה כי קליטתו בקרן ההשתלמות תקינה וכי ההפרשות לקרן מתבצעות בהתאם לנתוניו האישיים.
                    יודגש ! הרשת איננה נושאת באחריות לאי קליטה של העובד בקרן ההשתלמות והמעקב אחר ההפרשות ולא תישמע טענה כל שהיא בנושא זה מהעובד מלבד תיקון הפרשות באותה שנת המס בה העובד יפנה לרשת ובקשתו תימצא מוצדקת.
                  </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;29.&nbsp;</p>
                  <div>העובד יצורף לקרן פנסיה וקופת גמל בשיעורי הפרשות הנהוגים לגבי עובדי הוראה המועסקים בחינוך הרשמי ובהתאם לנוהל הצטרפות לקרן פנסיה ברשת. </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;30.&nbsp;</p>
                  <div>משכר העובד ינוכו תשלומי חובה אשר יועברו ע"י המעביד לרשויות.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;31.&nbsp;</p>
                  <div>על העובד לעבוד במסירות ובנאמנות ולשמור על כללים אתיים. </div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;32.&nbsp;</p>
                  <div>חוק הגנת הפרטיות, תשמ"א - 1981 (להלן "חוק הגנת הפרטיות").  העובד מאשר שידוע לו כי לצורך העסקת העובד ותשלום שכרו וכל הנובע מכך, בידי הרשת קיים מידע אודותיו כגון: פרטים אישיים, מסמכי השכלה ודירוג שכר, חו"ד פיקוח והערכות עבודה ועוד והעובד נותן את רשותו והסכמתו לרשת להחזיק ולעשות שימוש במידע אודותיו לשם כך.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;33.&nbsp;</p>
                  <div>בין צדדים יחולו אך ורק תנאי הודעה זו, מובהר בזאת כי שינוי מתנאי הודעה זו יהיה תקף רק אם נעשה בכתב ע"י מנהל כ.א. ברשת.</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;34.&nbsp;</p>
                  <div>למען הסר ספק, מובהר בזאת כי למנהל/ת בית הספר ולמפקח/ת אין סמכות ואין הרשאה מטעם הרשת לשנות תנאי מתנאי הודעה זו.</div>
                </div>
                <div className='d-flex pagebreak'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;35.&nbsp;</p>
                  <div>כל התחייבות ו/או הסכם בין העובד לבין הנהלת המוסד ו/או כל גורם אחר אינו מחייב את רשת החינוך התורני "בני יוסף".</div>
                </div>
                <div className='d-flex'>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;36.&nbsp;</p>
                  <div>כתובת העובד לצרכי התקשרות – על פי הכתובת המעודכנת במאגרי המידע שנמצאים ברשת באותה העת. כל הודעה שתישלח בדואר רשום לכתובות דלעיל תיחשב כאילו הגיעה לידי הנמען לאחר 72 שעות מעת משלוחה כאמור. הודעה שתישלח בדואר אלקטרוני תיחשב כאילו הגיעה לידי הנמען לאחר 12 שעות מעת משלוחה כאמור.</div>
                </div>
                <u>הצהרת העובד/ת:</u><br />
                אני, העובד/ת שפרטיו/ה מצוינים לעיל בסעיף 1, מצהיר/ה בזה שהפרטים המצוינים לעיל ב"הודעה לעובד - תנאי העסקה" מקובלים עלי וקבלתם מהווה תנאי להעסקתי ברשת החינוך התורני  "בני יוסף". ובחתימתי על מסמך זה אני מאשר שקראתי והבנתי את ה"הודעה לעובד" על כל סעיפיו ואני מסכים לכל האמור בו.<br /><br />
              </> :
                <>
                  <p>חשוב! בחתימתי על מסמך זה אני מאשר שקראתי והבנתי את האמור במסמך ואני מסכים כי העסקתי ברשת החינוך "בני יוסף" ע.ר. 580121952 הינה בהתאם לאמור בו.</p>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;</p>
                    <div>א.	הודעה זו מהווה 'הודעה לעובד' על פי חוק 'הודעה לעובד ולמועמד לעבודה וראיה לכך שהובאו לידיעתו כל תנאי העסקתו ברשת (להלן: - רשת החינוך 'בני יוסף') וההוראות והסעיפים המופיעים בערכת מסמכים לקליטת עובד.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;</p>
                    <div>	העובד שפרטיו מפורטים בסעיף 1 לעיל יועסק בבית ספר המפורט בסעיף 2 לעיל בתפקיד ובהיקף משרה המפורטים בסעיף 3 לעיל.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;</p>
                    <div>	העובד יהיה כפוף למנהל כח אדם ברשת ולמנהל בית הספר בכל הנוגע לביצוע תפקידיו וסדרי עבודתו.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;</p>
                    <div>	העסקת העובד ברשת החינוך "בני יוסף" וקליטתו במערכת, מותנים באישור סופי של הנהלת רשת החינוך "בני  יוסף" שתדון ותחליט בבקשה לאחר שהעובד יגיש את המסמכים לקליטת עובד חדש לרשת במלואם.  האישור יינתן בכתב חתום בידי מנהל כח אדם ברשת החינוך בלבד.
                    </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>נוסף על האמור, ידוע לעובד שבמידה וקיימת קרבת משפחה: העסקתו ברשת החינוך "בני יוסף", מותנית באישור סופי של הוועדה לקרבת משפחה של רשת החינוך "בני יוסף" שתדון ותחליט בבקשה לאחר הגשת המסמכים לקליטת עובד חדש במלואם.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>אין לראות בביצוע הליך כל שהוא ו/או אישור כל שהוא של מנהל ביה"ס ו/או המפקח/ת של רשת החינוך 'בני יוסף' ו/או בכל גורם אחר כאישור קבלה לעבודה ברשת.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>כמו כן, אין לראות בהנפקת תלוש שכר של רשת החינוך "בני  יוסף" כאישור העסקה.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;</p>
                    היקף המשרה:
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;א.&nbsp;</p>
                    <div>העובד יועסק בהתאם לתפקידו בהיקף משרה כפי שיקבע במצבת עובדים ובהתאם לשינויים במשך תקופת עבודתו ברשת, כולל שינויים שנובעים מתקצוב המוסד ע"י משרד החינוך. <br />
                      עובד שמועסק במשרה בת  100%, תעמוד המשרה על  40 שעות שבועיות.<br />
                      תשומת לב! במידה והמוסד בו העובד מועסק יקבל צו סגירה ממשרד החינוך מוסכם בזה שבסמיכות ובמקביל יונפק לעובד מכתב פיטורין תוך מתן הזכות לשימוע והודעה מוקדמת על פי דין.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ב.&nbsp;</p>
                    <div>הימים והשעות בהם העובד יועסק במשך השבוע, הינם בכפוף לסידור העבודה שיקבע ע"י מנהל אגף כח אדם ברשת ומנהל בית הספר בהתאם לצרכי בית הספר</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;6.&nbsp;</p>
                    <div>	כאשר צרכי בית הספר ואו התקציב מחייבים זאת, רשאית הרשת לשנות את סידור העבודה כולל ביצוע שינויים בתפקיד ובהיקפי משרה של העובד ולהודיע על כך לעובד בהודעה מוקדמת. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;7.&nbsp;</p>
                    <div>	בחתימת העובד על הודעה זו, העובד מצהיר כי הוא יועסק במוסד בתפקיד, בהיקף משרה ובתחולה כפי שמדווח עליו מעת לעת על ידי מנהל בית הספר במערכת השכר הממוחשבת ובדוחות הנוכחות החודשיים. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;8.&nbsp;</p>
                    <div>	העובד לא יעבוד שעות נוספות אלא באישור בכתב  ממנהל כח אדם של הרשת בלבד. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;9.&nbsp;</p>
                    <div>	העובד יהיה זכאי לחופשה שנתית לפי חוק חופשה שנתית תשי"א-.1951, מועד צאתו של העובד לחופשה ייקבע על פי לוח חופשות המופיע בסעיף 24 להלן בהתאמה לסוג המשרה בה הוא מועסק.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;10.&nbsp;</p>
                    <div>	על העובד לנצל את ימי החופשה השנתיים ולפיכך העובד לא יוכל לצבור ימי חופשה והעברתם משנה לשנה.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;11.&nbsp;</p>
                    <div>	העובד יהיה זכאי לדמי מחלה בהתאם להוראות חוק דמי מחלה תשל"ו- 1976 בלבד. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;12.&nbsp;</p>
                    <div>	מוצהר בזאת כי ימי מחלה צבורים אינם ניתנים לפדיון. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;13.&nbsp;</p>
                    <div>המשכורת המשולמת לעובד הינה בהתאם לדרוג השכר האחיד בתחולה של הרכיבים הבאים: שכר יסוד, וותק שנצבר כתוצאה מעבודתו במרכז, הסכם 1998, הסכם 2001, תוספת 2011, תוספת שקלית 2016, תוספת אחוזי 2016, איזון ותוספת מינימום בהתאם לזכאות. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;14.&nbsp;</p>
                    <div>נוסף על כל האמור:</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;א.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>שרת ו/או מנקה - יתוגמל בשכרו ברכיב תוספת כפיים.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ב.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>עובד במשרת סייע המוגדר תומכת חינוך - סייע בחינוך המיוחד וסל אישי שעל פי 'הסכם קיבוצי כללי – תומכות חינוך' שנחתם בין מרכז השלטון המקומי לבין הסתדרות העובדים הכללית החדשה – הסתדרות המעו"ף מיום 08 באוגוסט 2023, (להלן: - 'הסכם קיבוצי תומכות חינוך') העומד בהגדרה כ - 'תומכת חינוך', הוראות הסכם קיבוצי תומכת חינוך יחולו על תנאי העסקה והשכר של העובד כולל לעניין חופשות. בהקשר זה, במקרה של סתירה בין האמור בהסכם זה למופיע ב 'הסכם קיבוצי כללי – תומכות חינוך' המופיע בהסכם הקיבוצי יגבר על האמור בהסכם זה.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ג.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>עובד בתפקיד רופא - תעריף לשעה לשכר רופא כולל עלות מעביד, הינה תעריף שמשרד החינוך מעביר לרשת במערכת מת"מ.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;15.&nbsp;</p>
                    <div>	העובד יהיה זכאי לדמי ביגוד והבראה בהתאם למקובל במגזר הציבורי בדירוג האחיד. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;16.&nbsp;</p>
                    <div>	העובד יהיה זכאי להצטרף לקרן השתלמות בהתאם לנוהל הצטרפות לקרן  השתלמות ברשת יודגש ! הרשת איננה נושאת באחריות לאי קליטה של העובד בקרן ההשתלמות והמעקב אחר ההפרשות ולא תישמע טענה כל שהיא בנושא זה מהעובד מלבד תיקון הפרשות באותה שנת המס בה העובד יפנה לרשת ובקשתו תימצא מוצדקת. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;17.&nbsp;</p>
                    <div>	העובד יצורף לקרן פנסיה בהתאם לנוהל הצטרפות לקרן פנסיה ברשת.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;18.&nbsp;</p>
                    <div>	העובד יהיה זכאי לתשלום נסיעות וטלפון בהתאם לצווי הרחבה. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;19.&nbsp;</p>
                    <div>העובד מתחייב לציית להוראות הממונים עליו ולעבוד בתפקידו במסירות ונאמנות ולשמור על כללים אתיים. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;20.&nbsp;</p>
                    <div>	העובד מצהיר בזאת כי אין מניעה רפואית שיעבוד בתפקיד האמור בסעיף 3 להסכם</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;21.&nbsp;</p>
                    <div>	אין לראות באישור העסקה ברשת "בני  יוסף" כאישור על רצף זכויות ממקום עבודה קודם. כל התחשבנות שהיא ו/או זכויות ככול שישנם אם ישנם בין העובד לבין המעסיק הקודם עד למועד כניסת העובד לעבודה באמצעות "רשת החינוך" כולל זכאות לפיצויי פיטורין הינם על אחריות העובד. <br />
                      ידוע לעובד כי, התחשבנויות אלו הינם בינו לבין המעסיק הקודם ששלם את שכרו עד למועד כניסתו לעבודה באמצעות "רשת החינוך" באופן שהעובד לא יהיה זכאי/ת לכל תשלום מ"רשת החינוך" בקשר לתקופת עבודתו וסיום תקופת עבודתו אצל המעסיק הקודם.
                    </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;22.&nbsp;</p>
                    <div>	מובהר בזאת כי שינוי מהודעה זו יהיה תקף רק אם נעשה בכתב ונחתם ע"י מנהל כ.א. ברשת. וכי כל התחייבות ו/או הסכם בין העובד לבין הנהלת המוסד ו/או כל גורם אחר אינו מחייב את רשת החינוך התורני "בני יוסף".</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;23.&nbsp;</p>
                    <div>למען הסר ספק מובהר בזאת כי למנהל בית הספר אין הרשאה מטעם הרשת לשנות  הודעה זו ו/או סעיף מסעיפיה. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;24.&nbsp;</p>
                    <div>	לוח חופשות:</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;א.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>עובד במשרת שרת/מזכיר/ה/ניקיון: ערב ראש השנה, ערב יום כיפור, ערב חג וחג הסוכות כולל חול המועד ואסרו חג, שני ימים בחנוכה, שני ימי פורים, ערב חג וחג הפסח כולל חול המועד ואסרו חג, ערב חג השבועות, לג' בעומר, ט' באב, עשרה ימי חופשה במהלך חופשת הקיץ בחדשים יולי אוגוסט בתאום עם מנהל ביה"ס ובכפוף  לחוק חופשה שנתית תשי"א 1951. <br />
                      שימת לב! בימי עבודה בהם מתקיימת חופשה וילדי בית הספר שוהים בביתם, שרתים, מזכירים ועובדי הניקיון ייבצעו עבודות כוללות הנדרשות בבית הספר ואשר לא ניתן לבצען בימים בהם מתקיימים לימודים סדירים, על מנהל בית הספר להכין תכנית עבודה לימים אלו ולהעבירה לעובדים ולפקח על הביצוע בפועל. </div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ב.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>עובד במשרת סייעת/עו"ס/רופא: זכאי לימי חופשה כעובדי הוראה בבית הספר למעט שוני בחופשת קיץ בה העובדת זכאית לחופשה בשכר בת חודש ימים בלבד וכמפורט: אם העובדת ממשיכה בחודש יולי לעבוד בפועל בבית הספר, העובדת תהיה זכאית לקבל את חופשת הקיץ בתשלום בחודש אוגוסט. העדר עבודה בפועל בחודש יולי, חודש יולי יחשב חופשה בתשלום ובחודש אוגוסט העובדת תשהה בחל"ת.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ג.&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <div>עובד במשרת סייע המוגדר תומכת חינוך - סייע בחינוך המיוחד וסל אישי שעל פי 'הסכם קיבוצי כללי – תומכות חינוך' שנחתם בין מרכז השלטון המקומי לבין הסתדרות העובדים הכללית החדשה – הסתדרות המעו"ף מיום 08 באוגוסט 2023, (להלן: - 'הסכם קיבוצי תומכות חינוך') העומד בהגדרה כ - 'תומכת חינוך', לוח החופשות של העובד יהיה על פי הוראות הסכם קיבוצי תומכת חינוך. בהקשר זה, במקרה של סתירה בין האמור בהסכם זה למופיע ב 'הסכם קיבוצי כללי – תומכות חינוך' המופיע בהסכם הקיבוצי יגבר על האמור בהסכם זה.</div><br />
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;25.&nbsp;</p>
                    <div>חוק הגנת הפרטיות, תשמ"א - 1981 (להלן "חוק הגנת הפרטיות").  העובד מאשר שידוע לו כי לצורך העסקת העובד ותשלום שכרו וכל הנובע מכך, בידי הרשת קיים מידע אודותיו כגון: פרטים אישיים, מסמכי השכלה ודירוג שכר, חו"ד פיקוח והערכות עבודה ועוד והעובד נותן את רשותו והסכמתו לרשת להחזיק ולעשות שימוש במידע אודותיו לשם כך.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;26.&nbsp;</p>
                    <div>מוסכם בין הצדדים כי על יחסי העבודה בין הצדדים יחולו אך ורק המובא בהודעה זו. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;27.&nbsp;</p>
                    <div>כתובת העובד לצרכי התקשרות – על פי הכתובת המעודכנת במאגרי המידע שנמצאים ברשת באותה העת. כל הודעה שתישלח בדואר רשום לכתובות דלעיל תיחשב כאילו הגיעה לידי הנמען לאחר 72 שעות מעת משלוחה כאמור.</div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;28.&nbsp;</p>
                    <div>אנו מאחלים לך הצלחה מלאה בעבודתך. </div>
                  </div>
                  <div className='d-flex'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;29.&nbsp;</p>
                    <div>מסמכי קליטת עובד מנוסחים בלשון זכר לצורכי נוחות בלבד והם תקפים לשני המגדרים כאחד.</div>
                  </div><br />
                  <u>הצהרת העובד/ת:</u><br />
                  אני, העובד/ת שפרטיו/ה מצוינים לעיל בסעיף 1, מצהיר/ה ומאשר בזה שקראתי פרק 'הודעה לעובד – תנאי העסקה' ו/או הוסבר לי והבנתי את כל הפרטים המובאים בו ושכל הפרטים שמסרתי בטופס זה הם מלאים ונכונים. <br /><br />
                </>}
              <Row>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" readOnly value={date} />
                    <Form.Label>תאריך</Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4} />
                <Col xs={4} md={4} lg={4} xl={4} xxl={4} className='pagebreak'>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" disabled />
                    <Form.Label>חתימת העובד</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mb-3 d-print-none">
                <br />
                <hr />
                המשך התהליך:<br />
                א. יש להדפיס את הטופס<br />
                ב. מנהל ביה"ס, המועמד ומפקח הרשת צריכים לחתום במקומות המתאימים<br />
                ג. לסרוק ולהעלות את הטופס החתום במערכת בהמשך התהליך<br />
                <Button className="m-2" variant="primary" name="back" onClick={(e) => realSubmit(e.target.name)}>שמור כטיוטה</Button>
                <Button className="m-2" variant="primary" type="submit">הדפסת הטופס והמשך התהליך </Button>
              </div>

              <p>{index++}. <u>	אישור מנהל/ת בית הספר</u></p><br />
              אני הח"מ מנהל/ת בביה"ס
              <Row>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>שם בית הספר</Form.Label>
                    <Form.Control type="text" readOnly value={form.sname} />
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>עיר</Form.Label>
                    <Form.Control type="text" readOnly value={form.scity} />
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>סמל מוסד</Form.Label>
                    <Form.Control type="text" readOnly value={form.semel} />
                  </Form.Group>
                </Col>
              </Row><br />
              מבקש לקלוט את העובד/ת
              <Row>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>שם משפחה</Form.Label>
                    <Form.Control type="text" readOnly value={form.lname} />
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>שם פרטי</Form.Label>
                    <Form.Control type="text" readOnly value={form.fname} />
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>מספר ת.ז.</Form.Label>
                    <Form.Control type="text" readOnly value={form.tz} />
                  </Form.Group>
                </Col>
              </Row><br />
              ומאשר/ת כי העובד/ת חתם על טופס זה, על כל סעיפיו, בפניי.<br />
              <Row>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" readOnly value={date} />
                    <Form.Label>תאריך</Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" disabled />
                    <Form.Label>שם המנהל</Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" disabled />
                    <Form.Label>חתימת המנהל חותמת בית הספר</Form.Label>
                  </Form.Group>
                </Col>
              </Row><br />
              ----------------------------------------------------------------------------------------------
              <br /><br />
              <p>{index++}. <u>{form.formtype === 0 ? "אישור פדגוגי" : "אישור פיקוח"}</u></p><br />
              {form.formtype === 0 ?
                'אני הח"מ, מפקח/ת אזור __________________ ברשת החינוך "בני יוסף" מאשר/ת פדגוגית את בקשת המנהל/ת המפורטת לעיל. יובהר כי העסקת העובד הינה בכפוף לאישור הנהלת הרשת שתינתן בכתב בחתימת מנהל אגף כח אדם ברשת.' :
                'אני הח"מ, מפקח/ת אזור __________________ ברשת החינוך "בני יוסף" מאשר/ת את בקשת המנהל/ת המפורטת לעיל, בכפוף לאישור הנהלת הרשת.'
              }<br />
              הערות:___________________________________________________________________________________________
              <br /><br />
              <Row>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" disabled />
                    <Form.Label>שם המפקח/ת</Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" readOnly value={date} />
                    <Form.Label>תאריך</Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4} xxl={4}>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" disabled />
                    <Form.Label>חתימה וחותמת המפקח/ת</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Form>
      <div class="divFooter">{form.formtype === 0 ? `טופס קליטת עובד הוראה - ${year}` : `טופס קליטת עובד עזר -${year}`}</div>
    </Container>
  );
}

export default AddForm;
