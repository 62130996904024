import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Card, Col, Container, Button, Form, Table, Spinner, InputGroup, Offcanvas, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Status from './Status';
import axios from 'axios';

const Principal = () => {
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [rows3, setRows3] = useState([]);
    const [rows4, setRows4] = useState([]);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [show, setShow] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [msg, setmsg] = useState({ password: '', email: '' });
    const [form, setForm] = useState({ password: '', email: sessionStorage.getItem('email') });
    const [showmail, setShowmail] = useState(sessionStorage.getItem('email'));

    useEffect(() => {
        axios.get("api/schooldata")
            .then((res) => {
                const stat1 = res.data.rows.filter(row => row.status === 1)
                const stat2 = res.data.rows.filter(row => row.status === 2)
                const stat3 = res.data.rows.filter(row => row.status === 3)
                const stat4 = res.data.rows.filter(row => row.status === 4)
                setRows1(stat1);
                setRows2(stat2);
                setRows3(stat3);
                setRows4(stat4);
                if (stat1.length > 0) {
                    setOpen1(true)
                } else if (stat2.length > 0) {
                    setOpen2(true)
                } else if (stat3.length > 0) {
                    setOpen3(true)
                } else if (stat4.length > 0) {
                    setOpen4(true)
                }
                setSpinner(false)
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setmsg({ password: '', email: '' });
    }

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.name === 'email') {
            setShowmail(form.email)
            sessionStorage.setItem('email', form.email);
            axios.post("api/schoolinfo/email", { email: form.email })
                .then((res) => {
                    setmsg({ ...msg, email: 'מייל עודכן בהצלחה!' })
                }).catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            axios.post("api/schoolinfo/password", { password: form.password, email: showmail })
                .then((res) => {
                    setmsg({ ...msg, password: 'סיסמתך עודכנה בהצלחה!' })
                    setForm({ ...form, password: '' })
                }).catch((error) => {
                    console.error('Error:', error);
                });
        }

    }
    const cb = (id, num, rows) => {
        axios.post("api/delete/request", { id })
            .then((res) => {
                console.log(res.data.msg)
            }).catch((error) => {
                console.error('Error:', error);
            });
        num(rows.filter(row => row.id !== id))
    }
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }
    return (
        <>
            <Container>
                <Row className=''>
                    <Col xs={1} md={1} lg={3} xl={3} xxl={3}>
                        <div className='mt-3'>
                            {/*
                                <Link to="/schoolfiles">
                                <Button variant="outline-warning">
                                    העלאת קבצים לרשת
                                </Button>
                            </Link>
    
                            <Link to="/travel">
                                <Button variant="warning">
                                    הסעות
                                </Button>
                            </Link>*/}
                            <Link to="/confirmation">
                                <Button variant="warning">
                                    נתוני מורים
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={10} md={10} lg={6} xl={6} xxl={6}>
                        <div className='text-center'>
                            <Link to="/contestent?id=-1">
                                <Button variant="primary p-3 mt-3 me-4">בקשה לקליטת עובד עזר</Button>
                            </Link>
                            <Link to="/contestent?id=0">
                                <Button variant="primary p-3 mt-3 me-4">בקשה לקליטת עובד הוראה</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={1} md={1} lg={3} xl={3} xxl={3}>
                        <div className='text-start mt-3'>
                            <Button variant="warning" onClick={handleShow}>
                                הפרטים שלי
                                {showmail === '' &&
                                    <>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    <strong>חסר אימייל למשתמש</strong>
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="danger">1</Badge>
                                        </OverlayTrigger>
                                    </>
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row className='pt-3 pb-2'>
                    <Col xs={12} md={3} lg={3} xl={3} xxl={3}>
                        <Card role="button" className={open1 ? 'alert alert-primary shadow m-0 p-0 pe-3' : 'shadow-sm mb-1 pe-3'}
                            onClick={() => { setOpen1(true); setOpen2(false); setOpen3(false); setOpen4(false); }}>
                            <Card.Title>סטטוס 1</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted"> בעריכה – טרם הגשת בקשה</Card.Subtitle>
                            <Card.Text className='pb-1'>
                                קיים {spinner ? (<>
                                    <Spinner animation="border" role="status" size="sm">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </>) : rows1.length} בקשות
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col xs={12} md={3} lg={3} xl={3} xxl={3}>
                        <Card role="button" className={open2 ? 'alert alert-primary shadow m-0 p-0 pe-3' : 'shadow-sm mb-1 pe-3'}
                            onClick={() => { setOpen1(false); setOpen2(true); setOpen3(false); setOpen4(false); }}>
                            <Card.Title>סטטוס 2</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted"> הוגשה וממתינה לאישור הרשת</Card.Subtitle>
                            <Card.Text className='pb-1'>
                                קיים {spinner ? (<>
                                    <Spinner animation="border" role="status" size="sm">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </>) : rows2.length} בקשות
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col xs={12} md={3} lg={3} xl={3} xxl={3}>
                        <Card role="button" className={open3 ? 'alert alert-primary shadow m-0 p-0 pe-3' : 'shadow-sm mb-1 pe-3'}
                            onClick={() => { setOpen1(false); setOpen2(false); setOpen3(true); setOpen4(false); }}>
                            <Card.Title>סטטוס 3</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted"> לא אושרה וממתינה להשלמת פרטים</Card.Subtitle>
                            <Card.Text className='pb-1'>
                                קיים {spinner ? (<>
                                    <Spinner animation="border" role="status" size="sm">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </>) : rows3.length} בקשות
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col xs={12} md={3} lg={3} xl={3} xxl={3}>
                        <Card role="button" className={open4 ? 'alert alert-primary shadow m-0 p-0 pe-3' : 'shadow-sm mb-1 pe-3'}
                            onClick={() => { setOpen1(false); setOpen2(false); setOpen3(false); setOpen4(true); }}>
                            <Card.Title>סטטוס 4</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">אושרה בטיפול ברשת</Card.Subtitle>
                            <Card.Text className='pb-1'>
                                קיים {spinner ? (<>
                                    <Spinner animation="border" role="status" size="sm">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </>) : rows4.length} בקשות
                            </Card.Text>
                        </Card>
                    </Col>
                </Row>

                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>תאריך סטטוס</th>
                            <th>שם</th>
                            <th>תז</th>
                            <th>טל</th>
                            <th>סוג</th>
                            <th>פעולות</th>
                            <th>סטטוס</th>
                        </tr>
                    </thead>

                    {open1 &&
                        <thead>
                            {rows1.map(i => {
                                return (
                                    <Status key={i.id} people={i} fun={(id) => cb(id, setRows1, rows1)} />
                                )
                            })}
                        </thead>}
                    {open2 &&
                        <thead id="open2">
                            {rows2.map(i => {
                                return (
                                    <Status key={i.id} people={i} fun={(id) => cb(id, setRows2, rows2)} />
                                )
                            })}
                        </thead>}
                    {open3 &&
                        <thead id="open3">
                            {rows3.map(i => {
                                return (
                                    <Status key={i.id} people={i} fun={(id) => cb(id, setRows3, rows3)} />
                                )
                            })}
                        </thead>}
                    {open4 &&
                        <thead id="open4">
                            {rows4.map(i => {
                                return (
                                    <Status key={i.id} people={i} fun={(id) => cb(id, setRows4, rows4)} />
                                )
                            })}
                        </thead>}
                </Table>
            </Container>
            <Offcanvas show={show} onHide={handleClose} dir='rtl'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>פרטים שלי</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    שם משתמש: {sessionStorage.getItem('name')}<br />
                    סמל מוסד: {sessionStorage.getItem('semel')}<br />
                    עיר: {sessionStorage.getItem('city')}<br />
                    שם מוסד: {sessionStorage.getItem('mosName')}<br />
                    מייל: {showmail}<br />
                    <hr />
                    עדכון מייל: (אפשר לשים כמה כל עוד מפרידים בפסיק)
                    <Form name="email" onSubmit={(e) => handleClick(e)}>
                        <InputGroup className="mb-3" dir='ltr'>
                            <Button type="submit" variant="outline-warning">
                                עדכן
                            </Button>
                            <Form.Control className={showmail === '' && "border border-danger"} dir='rtl' required autoComplete="off" type={form.email.includes(',') ? "text" : "email"} placeholder="" name="email" value={form.email} onChange={(e) => handleChange(e)}
                            />
                        </InputGroup>
                    </Form>
                    <p>{msg.email}</p>
                    <hr />
                    עדכון סיסמה:
                    <Form name="password" onSubmit={(e) => handleClick(e)}>
                        <InputGroup className="mb-3" dir='ltr'>
                            <Button type="submit" variant="outline-warning">
                                עדכן
                            </Button>
                            <Form.Control dir='rtl' autoComplete="off" required type="password" placeholder="*****" minLength={4} name="password" value={form.password} onChange={(e) => handleChange(e)}
                            />
                        </InputGroup>
                    </Form>
                    <p>{msg.password}</p>
                    <hr />
                    <div className='text-center'>
                        <p>לאחר כל עדכון ישלח מייל</p>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default Principal