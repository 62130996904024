import axios from "axios";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom"; // For navigation

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const Dash = () => {
    const [data, setData] = useState({ filled: 0, partial: 0, none: 0 });
    const navigate = useNavigate(); // Initialize navigation

    useEffect(() => {
        axios.post("api/getteachersdata")
            .then((res) => {
                setData(res.data.rows);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    const chartData = {
        labels: ["סיימו", "לא סיימו"],
        datasets: [
            {
                data: [data.filled, data.none], // Use the filled and none values
                backgroundColor: ["#4CAF50", "#F44336"], // Colors for the pie slices
                hoverBackgroundColor: ["#45A049", "#E53935"], // Hover colors
                borderWidth: 1,
            },
        ],
    };
    const handleChartClick = (event, elements) => {
        if (elements.length > 0) {
            const clickedIndex = elements[0].index; // Index of the clicked segment
            if (clickedIndex === 0) {
                navigate("/filled"); // Navigate to the "filled" page
            } else if (clickedIndex === 1) {
                navigate("/not-filled"); // Navigate to the "not-filled" page
            }
        }
    };

    const handleLabelClick = (label) => {
        if (label === "Filled") {
            navigate("/filled");
        } else if (label === "None") {
            navigate("/not-filled");
        }
    };

    const options = {
        onClick: (event, elements) => handleChartClick(event, elements),
    };
    return (
        <>
            <div className="text-center pt-5">
                <h2>לחץ לפירוט נתונים</h2>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="text-center" style={{ width: "300px", height: "300px" }}>
                    <Pie data={chartData} options={options} />
                </div>

            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">

                <button
                    className="btn btn-link text-decoration-none text-primary ms-3"
                    onClick={() => handleLabelClick("None")}
                >
                    לא סיימו
                </button>
                <button
                    className="btn btn-link text-decoration-none text-primary"
                    onClick={() => handleLabelClick("Filled")}
                >
                    סיימו
                </button>
            </div>

        </>
    );
};

export default Dash;
