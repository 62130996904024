import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import {Link, useNavigate } from "react-router-dom"; // For navigation
import TableToExcel from "./helpers/Excel";
import { getFormattedDate } from "./helpers/functions";

const Filled = () => {
    const [data, setData] = useState([]);
   
    useEffect(() => {
        axios.post("api/getteachersdatafilled")
            .then((res) => {
                setData(res.data.rows);
                console.log(res.data.rows)
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);


    // Function to extract specific columns from the data
    const extractSpecificColumns = (data) => {
        return data.map((row) => {
            const { MosName, Semel, ty, Gender, Principal, Ptel, teachnum ,tech_cert,tech_teach,techboth,english_cert, english_teach, engboth, math_cert, math_teach,mathboth} = row;
            return {
                "שם מוסד": MosName,
                "סמל": Semel,
                "עיר": ty,
                "מין": Gender,
                "מנהל": Principal,
                "טל": Ptel,
                "מס מורים":teachnum,
                "מדעים מוסמך":tech_cert,
                "מדעים מלמד":tech_teach,
                "מדעים מוסמך ומלמד":techboth,
                "אנגלית מוסמך":english_cert, 
                "אנגלית מלמד":english_teach,
                "אנגלית מוסמך ומלמד":engboth,
                "מתמטיקה מוסמך":math_cert,
                "מתמטיקה מלמד":math_teach,
                "מתמטיקה מוסמך ומלמד":mathboth,
            };
        });
    };

    let specificColumnsData = extractSpecificColumns(data);
   
    return (
        <>
            <Container className='text-center'>
                <h3 className='pt-2'>
                    מוסדות שסיימו
                </h3>
                <hr />
                <Row className='mb-1'>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} >
                        <TableToExcel data={specificColumnsData} fileName={`data_${getFormattedDate()}`} />
                    </Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>שם מוסד</th>
                            <th>סמל</th>
                            <th>עיר</th>
                            <th>מין</th>
                            <th>מנהל</th>
                            <th>טל</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td><Link to={"/teachersdata?semel="+row.Semel}>{row.MosName}</Link></td>
                                    <td>{row.Semel}</td>
                                    <td>{row.ty}</td>
                                    <td>{row.Gender}</td>
                                    <td>{row.Principal}</td>
                                    <td>{row.Ptel}</td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>
                </Container>
        </>
    );
};

export default Filled;
